/* istanbul ignore file: test forms*/
import {FormValues} from '@wix/form-viewer';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {AddressModel} from '../../../../domain/models/Address.model';
import {ApiAddress} from '@wix/ambassador-ecom-v1-checkout/types';
import {DEFAULT_COUNTRY} from '../../constants';

export const getAddressFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  address?: AddressModel
): FormValues => {
  const subdivision = address?.subdivision?.includes('-') ? address?.subdivision?.split('-')[1] : address?.subdivision;
  return {
    address: {
      country: address?.country ?? DEFAULT_COUNTRY,
      city: address?.city ?? '',
      ...(subdivision ? {subdivision} : {}),
      ...(address?.postalCode ? {postal_code: address.postalCode} : {}),
      ...(address?.addressLine ? {address_line: address.addressLine} : {}),
      ...(address?.streetAddress?.name ? {street_name: address.streetAddress.name} : {}),
      ...(address?.streetAddress?.number ? {street_number: address.streetAddress.number} : {}),
      ...(checkoutSettings.addressLine2.show && address?.addressLine2 ? {address_line_2: address?.addressLine2} : {}),
    },
  };
};

export const getAddressFromAddressFormValues = (
  checkoutSettings: CheckoutSettingsModel,
  addressFormValues: FormValues
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ApiAddress | undefined => {
  const address = addressFormValues.address;
  if (typeof address === 'object' && !Array.isArray(address) && address !== null) {
    const streetAddress =
      address.street_name || address.street_number
        ? {
            name: (address.street_name as string) || undefined,
            number: (address.street_number as string) || undefined,
          }
        : undefined;

    return {
      country: address.country as string,
      city: address.city as string,
      ...(address.subdivision
        ? {
            subdivision: `${address.country as string}-${address.subdivision as string}`,
          }
        : {}),
      ...(address.postal_code ? {postalCode: address.postal_code as string} : {}),
      ...(address.address_line ? {addressLine: address.address_line as string} : {}),
      ...(streetAddress ? {streetAddress} : {}),
      ...(checkoutSettings.addressLine2.show && address.address_line_2
        ? {addressLine2: address.address_line_2 as string}
        : {}),
    };
  }
};

export const getCountryFromAddressFormValues = (addressFormValues: FormValues): string | undefined => {
  const address = addressFormValues.address;
  if (typeof address === 'object' && !Array.isArray(address) && address !== null) {
    return address.country as string;
  }
};
