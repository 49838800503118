import React from 'react';
import {Text, Card, TextPriority} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PickupInfoBox.st.css';
import {PickupInfoModel} from '../../../domain/models/PickupInfo.model';

export enum PickupInfoBoxDataHook {
  root = 'PickupInfoBox.root',
  addressTitle = 'PickupInfoBox.addressTitle',
  addressDetails = 'PickupInfoBox.addressDetails',
  deliveryTimeDetails = 'PickupInfoBox.deliveryTimeDetails',
  instructionsTitle = 'PickupInfoBox.instructionsTitle',
  instructionsDetails = 'PickupInfoBox.instructionsDetails',
}

export interface PickupInfoBoxProps {
  pickupInfo?: PickupInfoModel;
}

const PickupInfoBox = ({pickupInfo}: PickupInfoBoxProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <div className={classes.root}>
      <Card data-hook={PickupInfoBoxDataHook.root} className={classes.card}>
        {!!pickupInfo?.address && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.addressTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_address_header()}
            </Text>
            <Text data-hook={PickupInfoBoxDataHook.addressDetails} priority={TextPriority.secondary}>
              {localeKeys.checkout.delivery_method.pickup_address({
                addressLine: pickupInfo?.address?.addressLine,
                city: pickupInfo?.address?.city,
                country: pickupInfo?.address?.countryFullname,
                subdivision: pickupInfo?.address?.subdivisionFullname,
                zipCode: pickupInfo?.address?.postalCode,
              })}
            </Text>
          </Card.Container>
        )}
        {!!pickupInfo?.deliveryTime && (
          <Card.Container>
            <Text
              data-hook={PickupInfoBoxDataHook.deliveryTimeDetails}
              priority={TextPriority.secondary}
              className={classes.deliveryTime}>
              {pickupInfo.deliveryTime}
            </Text>
          </Card.Container>
        )}
        {!!pickupInfo?.instructions && (
          <Card.Container>
            <Text
              tagName={'h4'}
              data-hook={PickupInfoBoxDataHook.instructionsTitle}
              priority={TextPriority.primary}
              className={classes.title}>
              {localeKeys.checkout.pickup_details_form.store_pickup_instructions_header()}
            </Text>
            <Text
              data-hook={PickupInfoBoxDataHook.instructionsDetails}
              priority={TextPriority.secondary}
              className={classes.instructions}>
              {pickupInfo.instructions}
            </Text>
          </Card.Container>
        )}
      </Card>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default React.memo(PickupInfoBox);
