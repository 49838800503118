import {FORMS_TEMPLATE_IDS} from '../../constants';
import {FormError, FormValues, FormViewer, FormViewerHandle} from '@wix/form-viewer/widget';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';

export interface FormProps {
  formRef: React.MutableRefObject<FormViewerHandle | null>;
  formValues: FormValues;
  setFormValues: (value: ((prevState: FormValues) => FormValues) | FormValues) => void;
  formErrors: FormError[];
  setFormErrors: (value: ((prevState: FormError[]) => FormError[]) | FormError[]) => void;
}

export interface ContactFormProps extends FormProps {
  overridePhone?: boolean;
}

const ContactForm = ({
  formRef,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  overridePhone,
}: ContactFormProps) => {
  const {i18n} = useTranslation();
  const localeKeys = useLocaleKeys();
  const {
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();

  return (
    <FormViewer
      i18n={i18n}
      ref={formRef}
      overrides={{
        first_name: {
          label: localeKeys.checkout.address_information.first_name_label(),
        },
        last_name: {label: localeKeys.checkout.address_information.last_name_label()},
        phone: {
          label: localeKeys.checkout.address_information.phone_number_label(),
          hidden: !checkoutSettings.phone.show && !overridePhone,
          required: checkoutSettings.phone.mandatory || overridePhone,
        },
        company_name: {
          label: localeKeys.checkout.address_information.company_label(),
          hidden: !checkoutSettings.companyName.show,
          required: checkoutSettings.companyName.mandatory,
        },
      }}
      formId={FORMS_TEMPLATE_IDS.CONTACT}
      values={formValues}
      errors={formErrors}
      onChange={setFormValues}
      onValidate={setFormErrors}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default React.memo(ContactForm);
